import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import * as AdminStore from '../../store/reducers/admin'
import StaffManagement from './StaffManagement'
import AccessManagement from './AccessManagement'
import Programs from './Programs'
import Certificates from './Certificates'
import Facilities from './Facilities'
import HelpGuides from './HelpGuides'
import OpeningMessages from './OpeningMessages'
import ManageSchools from './ManageSchools'
import MenuItems from './MenuItems'
import Holidays from './Holidays'
import Surveys from './Surveys'
import Themes from './Themes'
import Logs from './Logs'
import AppSettings from './AppSettings'
import {
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type ScheduleProps =
    { tab: string, index: number, staff: any } &
    typeof AdminStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Admin extends React.PureComponent<ScheduleProps> {
    public render() {
      if (this.props.staff.superadmin) {
        var panes = [
          { menuItem: 'Staff Management', render: () => <Tab.Pane><StaffManagement /></Tab.Pane> },
          { menuItem: 'Access Levels', render: () => <Tab.Pane><AccessManagement /></Tab.Pane> },
          { menuItem: 'Opening Messages', render: () => <Tab.Pane><OpeningMessages /></Tab.Pane> },
          { menuItem: 'Programs', render: () => <Tab.Pane><Programs /></Tab.Pane> },
          { menuItem: 'Certificates', render: () => <Tab.Pane><Certificates /></Tab.Pane> },
          { menuItem: 'Facilities', render: () => <Tab.Pane><Facilities /></Tab.Pane> },
          { menuItem: 'Menu Items', render: () => <Tab.Pane><MenuItems /></Tab.Pane> },
          { menuItem: 'Manage Schools', render: () => <Tab.Pane><ManageSchools /></Tab.Pane> },
          { menuItem: 'Holidays', render: () => <Tab.Pane><Holidays /></Tab.Pane> },
          { menuItem: 'Surveys', render: () => <Tab.Pane><Surveys /></Tab.Pane> },
          { menuItem: 'Help Guides', render: () => <Tab.Pane><HelpGuides /></Tab.Pane> },
          //{ menuItem: 'Themes', render: () => <Tab.Pane><Themes /></Tab.Pane> },
          { menuItem: 'Logs', render: () => <Tab.Pane><Logs /></Tab.Pane> },
          { menuItem: 'App Settings', render: () => <Tab.Pane><AppSettings /></Tab.Pane> }
        ]
      } else {
        var panes = [
          { menuItem: 'Staff Management', render: () => <Tab.Pane><StaffManagement /></Tab.Pane> },
          { menuItem: 'Access Levels', render: () => <Tab.Pane><AccessManagement /></Tab.Pane> },
          { menuItem: 'Opening Messages', render: () => <Tab.Pane><OpeningMessages /></Tab.Pane> },
          { menuItem: 'Programs', render: () => <Tab.Pane><Programs /></Tab.Pane> },
          { menuItem: 'Certificates', render: () => <Tab.Pane><Certificates /></Tab.Pane> },
          { menuItem: 'Facilities', render: () => <Tab.Pane><Facilities /></Tab.Pane> },
          { menuItem: 'Menu Items', render: () => <Tab.Pane><MenuItems /></Tab.Pane> },
          { menuItem: 'Manage Schools', render: () => <Tab.Pane><ManageSchools /></Tab.Pane> },
          { menuItem: 'Holidays', render: () => <Tab.Pane><Holidays /></Tab.Pane> }
        ]
      }

      return (
        <Fragment>
          <Tab menu={{ attached: false, style: { overflow: 'auto' } }} panes={panes} className='blue' activeIndex={this.props.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchTab(activeIndex) }} />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { tab: state.drawer.tab, index: state.admin.index, staff: state.staff.staff } },
  { ...AdminStore.actionCreators, ...PanesStore.actionCreators }
)(Admin as any)