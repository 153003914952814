import React from 'react'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '..'
import agent from '../../agent'
import { FetchStaffFavoritesAction } from './staff'
import { convertToRaw } from 'draft-js'
import { createEditorState } from 'medium-draft'
import { toast } from 'react-toastify'
import { connect, disconnect, send } from '@giantmachines/redux-websocket'

const qs = require('qs')
const _ = require('underscore')

type Report = {
    data: any,
    copyright: string,
    url: string,
    urls: any[],
    description: any,
    loading: boolean,
    ready: boolean,
    generated: any,
    facilities: any[],
    programs: any[],
    funding: any[],
    positions: any[],
    levels: any[]
}

export interface ChangeReportAction { type: 'CHANGE_REPORT', report: string, facilities: any[], programs: any[], funding: any[], positions: any[], levels: any[], data: any | null }
export interface FetchDescriptionAction { type: 'FETCH_DESCRIPTION', report: string, description: any }
interface DataChangeAction { type: 'REPORT_DATA_CHANGE', report: string, key: string, value: any }
interface LoadingReportAction { type: 'LOADING_REPORT', report: string, loading: boolean }
interface FetchEthnicitiesAction { type: 'FETCH_ETHNICITIES', ethnicities: any[] }
interface FetchFacilitiesAction { type: 'FETCH_FACILITIES', facilities: any[] }
interface FetchPositionsAction { type: 'FETCH_POSITIONS', positions: any[] }
interface FetchProgramsAction { type: 'FETCH_PROGRAMS', programs: any[] }
interface GenerateReportAction { type: 'GENERATE_REPORT', report: string, data: any }
interface UpdateDescriptionAction { type: 'UPDATE_REPORT_DESCRIPTION', report: string, state: any }
interface ClearEdHistoryInmatesAction { type: 'CLEAR_EDHISTORY_INMATES' }
interface SearchEdHistoryInmatesAction { type: 'SEARCH_EDHISTORY_INMATES', value: any }
interface UpdateEdHistoryInmatesAction { type: 'UPDATE_EDHISTORY_INMATES', inmates: any[] }
interface FetchReportFavoritesAction { type: 'FETCH_REPORT_FAVORITES', favorites: any[] }
interface ClassesLoadingAction { type: 'SEARCH_REPORT_CLASSES_LOADING', num: 1 | 2, value: string, report: string }
interface ClassesRosterLoadingAction { type: 'SEARCH_REPORT_CLASSES_ROSTER_LOADING', num: 1 | 2, value: string, report: string }
interface InmatesLoadingAction { type: 'SEARCH_REPORT_INMATES_LOADING', num: 1 | 2, value: string }
interface InstructorsLoadingAction { type: 'SEARCH_REPORT_INSTRUCTORS_LOADING', num: 1 | 2, value: string }
interface SearchClassesAction { type: 'SEARCH_REPORT_CLASSES', num: 1 | 2, classes: any[], report: string }
interface SearchRosterClassesAction { type: 'SEARCH_REPORT_ROSTER_CLASSES', num: 1 | 2, classes: any[], report: string }
interface SearchInmatesAction { type: 'SEARCH_REPORT_INMATES', num: 1 | 2, inmates: any[] }
interface SearchInstructorsAction { type: 'SEARCH_REPORT_INSTRUCTORS', num: 1 | 2, instructors: any[] }
interface SelectClassAction { type: 'SELECT_REPORT_CLASS', num: 1 | 2, key: number, value: string, report: string}
interface SelectRosterClassAction { type: 'SELECT_REPORT_ROSTER_CLASS', num: 1 | 2, key: number, value: string, report: string }
interface SelectInmateAction { type: 'SELECT_REPORT_INMATE', num: 1 | 2, key: number, value: string }
interface SelectInstructorAction { type: 'SELECT_REPORT_INSTRUCTOR', num: 1 | 2, key: number, value: string }
interface SetURLAction { type: 'SET_REPORT_URL', report: string, url: string }
interface SetURLsAction { type: 'SET_REPORT_URLS', report: string, url: string, title: string }
interface SetEdHistoryURLAction { type: 'SET_EDHISTORY_URL', recordID: number, url: string }
interface ReportOpenedAction { type: 'REPORT::OPEN' }
interface ReportClosedAction { type: 'REPORT::CLOSED' }
interface ReportMessageAction { type: 'REPORT::MESSAGE', payload: any }

type KnownAction = ReportMessageAction | ReportOpenedAction | ReportClosedAction | SetURLAction | SetURLsAction | SetEdHistoryURLAction | InstructorsLoadingAction | InmatesLoadingAction | ClassesLoadingAction | ClassesRosterLoadingAction | SearchInstructorsAction | SearchInmatesAction | SearchClassesAction | SearchRosterClassesAction | SelectInstructorAction | SelectInmateAction | SelectClassAction | SelectRosterClassAction | FetchProgramsAction | FetchFacilitiesAction | FetchPositionsAction | FetchStaffFavoritesAction | FetchReportFavoritesAction | ClearEdHistoryInmatesAction | SearchEdHistoryInmatesAction | UpdateEdHistoryInmatesAction | ChangeReportAction | FetchDescriptionAction | DataChangeAction | UpdateDescriptionAction | LoadingReportAction | FetchEthnicitiesAction | GenerateReportAction

export interface ReportsState {
    report: string,
    reports: {[key:string]: Report},
    favorites: any[],
    connected: boolean,
    options: {
        ethnicities: any[],
        inmates: any[],
        facilities: any[],
        programs: any[],
        positions: any[]
    }
}

export const reportDefaults:any = {
    attendance: {
        facilities: [0],
        programs: [0],
        displayType: 'html',
        orientation: 'landscape',
        reportType: 'class',
        active: 'active',
        attnType: 'sessions',
        breakdown: 'class',
        reportTypes: [{key:0,value:'class',text:'Class'},{key:1,value:'inmate',text:'Student'},{key:2,value:'cancellations',text:'Cancellations'}],
        classes: [],
        inmates: [],
        instructors: [],
        class: '',
        inmate: '',
        instructor: '',
        ethnicity: '',
        educationStatus: '',
        classID: 0,
        inmateID: 0,
        instructorID: 0,
        classesLoading: false,
        inmatesLoading: false,
        instructorsLoading: false,
        adminDeletes: false
    },
    quarterly: {
        facilities: [0],
        programs: [0],
        displayType: 'html',
        orientation: 'landscape',
        reportType: 'standard',
        selection: 'single',
        classFilter: 'all',
        filterClasses: [],
        reportTypes: [{key:0,value:'standard',text:'Standard'},{key:1,value:'detailed',text:'Detailed'},{key:2,value:'completions',text:'Completions'},{key:3,value:'detailed-completions',text:'Detailed Completions'},{key:4,value:'demographics',text:'Demographics'},{key:5,value:'participation',text:'Participation'},{key:6,value:'detailed-participation',text:'Detailed Participation'},{key:7,value:'weekly-participation',text:'Weekly Participation'},{key:8,value:'ethnic',text:'Ethnic Access'}],
        under22: false,
        unverified: false,
        showSignups: false,
        showNames: true,
        showClasses: true,
        showCourses: true,
        showPrograms: true,
        showDates: false,
        filterNoShows: false,
        unique: false,
        adminDeletes: false
    },
    roster: {
        facilities: [0],
        positions: [0],
        levels: ['all'],
        testType: 'B',
        displayType: 'html',
        orientation: 'landscape',
        reportType: 'name',
        reportTypes: [{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'},{key:2,value:'housing',text:'Housing'},{key:3,value:'class',text:'Class'},{key:4,value:'tabe',text:'TABE'},{key:5,value:'staff',text:'Staff'}],
        filterBy: 'active',
        searchFilter: 'not',
        tabeFilters: [],
        login: 'all',
        status: 'all',
        location: 'any',
        gradTypes: ['any'],
        age: '22',
        months: '1',
        showSchools: false,
        adminDeletes: false
    },
    tabe: {
        facilities: [0],
        displayType: 'html',
        orientation: 'landscape',
        reportType: 'standard',
        reportTypes: [{key:0,value:'standard',text:'Standard'},{key:1,value:'archived',text:'Archived'},{key:2,value:'roster',text:'Roster'},{key:3,value:'compare',text:'Compare'}],
        verified: false,
        filteredBy: [],
        abeHours: 0,
        num: 2,
        adminDeletes: false
    },
    graduated: {
        facilities: [0],
        reportType: 'standard',
        reportTypes: [{key:0,value:'standard',text:'Standard'}],
        displayType: 'html',
        gradType: 'E',
        orientation: 'landscape',
        sortBy: 'name',
        graduated: true,
        facility: true,
        adminDeletes: false
    },
    ged: {
        facilities: [0],
        reportType: 'standard',
        reportTypes: [{key:0,value:'standard',text:'Standard'},{key:1,value:'best',text:'Best'},{key:2,value:'graduated',text:'Graduated'}],
        displayType: 'html',
        orientation: 'landscape',
        sortBy: 'name',
        passing: 145,
        graduated: false,
        adminDeletes: false
    },
    hiset: {
        facilities: [0],
        reportType: 'standard',
        reportTypes: [{key:0,value:'standard',text:'Standard'},{key:1,value:'graduated',text:'Graduated'}],
        displayType: 'html',
        orientation: 'landscape',
        sortBy: 'name',
        graduated: false,
        adminDeletes: false
    },
    kamakani: {
        facilities: [0],
        displayType: 'html',
        orientation: 'landscape',
        reportType: 'course-facility',
        classFilter: 'all',
        filterClasses: [],
        reportTypes: [{key:0,value:'course-facility',text:'Course / Facility'},{key:1,value:'course',text:'Course'},{key:2,value:'facility',text:'Facility'},{key:3,value:'funding',text:'Funding'},{key:4,value:'funding-facility',text:'Funding / Facility'}],
        adminDeletes: false
    },
    descriptions: {
        facilities: [0],
        programs: [0],
        displayType: 'html',
        orientation: 'landscape',
        filterBy: 'templates',
        classFilter: 'all',
        filterClasses: [],
        adminDeletes: false
    },
    utilization: {
        displayType: 'html',
        orientation: 'landscape',
        classFilter: 'all',
        filterClasses: [],
        adminDeletes: false
    },
    funding: {
        facilities: [0],
        programs: [0],
        funding: ['all'],
        displayType: 'html',
        orientation: 'landscape',
        classFilter: 'all',
        filterClasses: [],
        adminDeletes: false
    },
    tracing: {
        facilities: [0],
        displayType: 'html',
        orientation: 'landscape',
        classFilter: 'all',
        filterClasses: [],
        adminDeletes: false
    },
    cspr: {
        facilities: [0],
        reports: ['all'],
        participated: true,
        displayType: 'html',
        orientation: 'landscape',
        classFilter: 'all',
        filterClasses: [],
        adminDeletes: false
    },
    edhistory: {
        inmates: [],
        info: [],
        transcript: ['grades'],
        tabe: 'all',
        domains: true,
        displayType: 'pdf',
        orientation: 'landscape',
        inmate: '',
        inmatesLoading: false,
        adminDeletes: false
    },
    recidivism: {
        reportType: 'baseline',
        reportTypes: [{key:0,value:'standard',text:'Standard'},{key:1,value:'baseline',text:'Baseline'},{key:2,value:'population',text:'Population'}],
        displayType: 'pdf',
        orientation: 'landscape',
        adminDeletes: false
    },
    ands: {
        facilities: [0],
        sortBy: 'name',
        displayType: 'html',
        orientation: 'landscape',
        adminDeletes: false
    }
}

const padDigits = (number:number, digits:number) => {
    return (number < 0 ? '-' : '') + Array(Math.max(digits - String(Math.abs(number)).length + 1, 0)).join('0') + Math.abs(number)
}

export const buildParams = (data: any, params: URLSearchParams) => {
    for (let [key, value] of Object.entries(data)) {
        if (value !== undefined && value !== null && value !== '' && key !== 'reportTypes') {
            if (_.isArray(value)) {
                for (let ovalue of _.filter(Object.values(value), (ovalue:any) => _.isString(ovalue) || _.isNumber(ovalue))) {
                    params.append(key, ovalue as string)
                }
            } else if (typeof value.toString === "function") {
                params.append(key, value.toString())
            }
        }
    }
    return params
}

export const groupParamsByKey = (params: URLSearchParams) => [...params.entries()].reduce((acc:any, tuple:any) => {
    let [key, val] = tuple;
    if (key === 'facilities' || key === 'programs' || key === 'positions' || key === 'filterClasses') {
        val = parseInt(val)
    }
    if(acc.hasOwnProperty(key)) {
        if(Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val]
        } else {
            acc[key] = [acc[key], val];
        }
    } else if (key === 'facilities' || key === 'programs' || key === 'positions' || key === 'filterClasses') {
        acc[key] = [val];
    } else if (key === 'reports' || key === 'funding' || key === 'levels' || key === 'filteredBy' || key === 'tabeFilters' || key === 'gradTypes') {
        acc[key] = [val];
    } else if (val === "true" || val === "false") {
        if (val === "true") {
            acc[key] = true
        } else {
            acc[key] = false
        }
    } else {
        acc[key] = val;
    }
   
    return acc;
}, {})

var typingTimeout: any

export const actionCreators = {
    socketConnect: () => (connect(`${window.location.protocol.startsWith('https') ? 'wss' : 'ws'}://${window.location.host}/reports/data`, 'REPORT') as any),
    socketDisconnect: () => (disconnect('REPORT') as any),
    setURL: (report: string, url: string) => ({ type: 'SET_REPORT_URL', report: report, url: url } as SetURLAction),
    setURLs: (report: string, title: string, url: string) => ({ type: 'SET_REPORT_URLS', report: report, url: url, title: title } as SetURLsAction),
    setEdHistoryURL: (recordID: number, url: string) => ({ type: 'SET_EDHISTORY_URL', recordID: recordID, url: url } as SetEdHistoryURLAction),
    searchUpdate: (value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        dispatch({ type: 'SEARCH_EDHISTORY_INMATES', value: value } as SearchEdHistoryInmatesAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            params.append('active', 'all')
            const { inmates } = await agent.Data.fetchInmates(params)
            const options = _.map(inmates, (data:any) => { return { key: data.key, value: data.key, text: `${data.title} [${data.description}]` }; })
            dispatch({ type: 'UPDATE_EDHISTORY_INMATES', inmates: options } as UpdateEdHistoryInmatesAction)
        }, 1500)
    },
    dataChange: (report: string, key: string, value: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (key === 'facilities' || key === 'programs' || key === 'positions') {
            if (value.slice(-1)[0] === 0) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: [0] } as DataChangeAction)
            } else if (value.length === 0) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: [0] } as DataChangeAction)
            } else if (_.any(value, (v:number) => v > 0)) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: _.filter(value, (v:number) => v > 0) } as DataChangeAction)
            } else {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: value } as DataChangeAction)
            }
        } else if (key === 'funding' || key === 'reports' || key === 'levels' || (report === 'edhistory' && key === 'info')) {
            if (value.slice(-1)[0] === 'all') {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: ['all'] } as DataChangeAction)
            } else if (value.length === 0) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: ['all'] } as DataChangeAction)
            } else if (_.any(value, (v:string) => v !== 'all')) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: _.filter(value, (v:string) => v !== 'all') } as DataChangeAction)
            } else {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: value } as DataChangeAction)
            }
        } else if (key === 'gradTypes') {
            if (value.slice(-1)[0] === 'all') {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: ['any'] } as DataChangeAction)
            } else if (value.length === 0) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: ['any'] } as DataChangeAction)
            } else if (_.any(value, (v:string) => v !== 'any')) {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: _.filter(value, (v:string) => v !== 'any') } as DataChangeAction)
            } else {
                dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: value } as DataChangeAction)
            }
        } else if (report === 'edhistory' && key === 'inmates') {
            dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: value } as DataChangeAction)
            dispatch({ type: 'CLEAR_EDHISTORY_INMATES' } as ClearEdHistoryInmatesAction)
        } else {
            dispatch({ type: 'REPORT_DATA_CHANGE', report: report, key: key, value: value } as DataChangeAction)
            if (key === 'reportType') {
                var { description } = await agent.Reports.fetchTypeDescription(report, value)
                dispatch({ type: 'FETCH_DESCRIPTION', report: report, description: description } as FetchDescriptionAction)
            }
        }
    },
    changeReport: (report: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { facilityData, programData, fundingData, positionData, levelData } = await agent.Reports.fetchReportFacilities(report)
        dispatch({ type: 'CHANGE_REPORT', report: report, facilities: facilityData, programs: programData, funding: fundingData, positions: positionData, levels: levelData, data: null } as ChangeReportAction)
        if ("reportType" in reportDefaults[report]) {
            var { description } = await agent.Reports.fetchTypeDescription(report, reportDefaults[report].reportType)
        } else {
            var { description } = await agent.Reports.fetchDescription(report)
        }
        dispatch({ type: 'FETCH_DESCRIPTION', report: report, description: description } as FetchDescriptionAction)
    },
    fetchFavorites: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { reports } = await agent.Data.fetchFavorites()
        dispatch({ type: 'FETCH_REPORT_FAVORITES', favorites: reports } as FetchReportFavoritesAction)
    },
    favorite: (type: string, title: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        let params = new URLSearchParams()
        params.append('title', title)
        params.append('type', type)
        params.append('data', btoa(JSON.stringify(state.reports[state.report].data)))
        const { error } = await agent.Reports.favorite(params)
        if (error !== null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Report favorited')
            const { reports } = await agent.Data.fetchFavorites()
            dispatch({ type: 'FETCH_REPORT_FAVORITES', favorites: reports } as FetchReportFavoritesAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    unfavorite: (type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().schedules
        let params = new URLSearchParams()
        params.append('type', type)
        const { error } = await agent.Reports.unfavorite(params)
        if (error !== null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Report unfavorited')
            const { reports } = await agent.Data.fetchFavorites()
            dispatch({ type: 'FETCH_REPORT_FAVORITES', favorites: reports } as FetchReportFavoritesAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    lastCY: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var year = (new Date()).getFullYear() - 1;
        var begDate = `01/01/${year}`
        var endDate = `12/31/${year}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    thisCY: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var year = (new Date()).getFullYear()
        var endMonth = (new Date()).getMonth() + 1
        var endDay = endMonth === 2 ? 28 : (endMonth === 4 || endMonth === 6 || endMonth === 9 || endMonth === 11) ? 30 : 31
        var begDate = `01/01/${year}`
        var endDate = `${padDigits(endMonth, 2)}/${endDay}/${year}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    lastFY: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var begMonth = 7
        var endMonth = 6
        var begYear = (new Date()).getMonth() > 5 ? (new Date()).getFullYear() - 1 : (new Date()).getFullYear() - 2
        var endYear = (new Date()).getMonth() > 5 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1
        var begDate = `${padDigits(begMonth, 2)}/01/${begYear}`
        var endDate = `${padDigits(endMonth, 2)}/30/${endYear}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    thisFY: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var begMonth = 7
        var endMonth = (new Date()).getMonth() + 1
        var begYear = (new Date()).getMonth() > 5 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1
        var endYear = (new Date()).getFullYear()
        var begDate = `${padDigits(begMonth, 2)}/01/${begYear}`
        var endDate = `${padDigits(endMonth, 2)}/30/${endYear}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    lastQTR: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var year = (new Date()).getMonth() - 3 < 0 ? (new Date()).getFullYear() -1 : (new Date()).getFullYear()
        var begMonth = (new Date()).getMonth() - 3 < 0 ? 10 : (new Date()).getMonth() - 3 < 3 ? 1 : (new Date()).getMonth() -3 < 6 ? 4 : 7
        var endMonth = (new Date()).getMonth() - 3 < 0 ? 12 : (new Date()).getMonth() - 3 < 3 ? 3 : (new Date()).getMonth() -3 < 6 ? 6 : 9
        var endDay = endMonth === 3 || endMonth === 12 ? 31 : 30
        var begDate = `${padDigits(begMonth, 2)}/01/${year}`
        var endDate = `${padDigits(endMonth, 2)}/${endDay}/${year}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    lastHalf: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var begYear = (new Date()).getMonth() > 5 ? (new Date()).getFullYear() : (new Date()).getFullYear() -1
        var endYear = (new Date()).getMonth() > 5 ? (new Date()).getFullYear() : (new Date()).getFullYear() -1
        var begMonth = (new Date()).getMonth() > 5 ? 1 : 7
        var endMonth = (new Date()).getMonth() > 5 ? 6 : 12
        var endDay = endMonth === 3 || endMonth === 12 ? 31 : 30
        var begDate = `${padDigits(begMonth, 2)}/01/${begYear}`
        var endDate = `${padDigits(endMonth, 2)}/${endDay}/${endYear}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    lastMonth: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports
        var year = (new Date()).getMonth() - 1 === -1 ? (new Date()).getFullYear() -1 : (new Date()).getFullYear()
        var month = (new Date()).getMonth() - 1 === -1 ? 12 : (new Date()).getMonth()
        var endDay = month === 2 ? 28 : (month === 4 || month === 6 || month === 9 || month === 11) ? 30 : 31
        var begDate = `${padDigits(month, 2)}/01/${year}`
        var endDate = `${padDigits(month, 2)}/${endDay}/${year}`
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'startDate', value: begDate } as DataChangeAction)
        dispatch({ type: 'REPORT_DATA_CHANGE', report: state.report, key: 'endDate', value: endDate } as DataChangeAction)
    },
    fetchEthnicities: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { ethnicities } = await agent.Data.fetchEthnicities()
        dispatch({ type: 'FETCH_ETHNICITIES', ethnicities: ethnicities } as FetchEthnicitiesAction)
    },
    fetchFacilities: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { facilities } = await agent.Data.fetchAllFacilities()
        dispatch({ type: 'FETCH_FACILITIES', facilities: facilities } as FetchFacilitiesAction)
    },
    fetchPositions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { positions } = await agent.Data.fetchPositions()
        dispatch({ type: 'FETCH_POSITIONS', positions: positions } as FetchPositionsAction)
    },
    fetchPrograms: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { programs } = await agent.Data.fetchPrograms()
        dispatch({ type: 'FETCH_PROGRAMS', programs: programs } as FetchProgramsAction)
    },
    generateANDs: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        let data = qs.parse(window.location.search.substr(1, window.location.search.length));
        buildParams(data, params)
        dispatch({ type: 'LOADING_REPORT', report: 'ands', loading: true } as LoadingReportAction)
        const reportData = await agent.Reports.fetchANDs(params)
        dispatch({ type: 'GENERATE_REPORT', report: 'ands', data: reportData } as GenerateReportAction)
    },
    generateAttendance: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.attendance
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'attendance', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'attendance', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateQuarterly: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.quarterly
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'quarterly', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'quarterly', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateRoster: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.roster
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'roster', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'roster', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateEdHistory: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.edhistory
        let params = new URLSearchParams()
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'edhistory', loading: true } as LoadingReportAction)
        const reportData = await agent.Reports.fetchEducationalHistory(params)
        dispatch({ type: 'GENERATE_REPORT', report: 'edhistory', data: reportData } as GenerateReportAction)
    },
    generateRecidivism: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.recidivism
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'recidivism', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'recidivism', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateCSPR: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.cspr
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'cspr', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'cspr', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateDescriptions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.descriptions
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'descriptions', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'descriptions', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateFunding: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.funding
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'funding', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'funding', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateGraduated: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.graduated
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'graduated', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'graduated', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateGED: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.ged
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'ged', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'ged', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateHiSET: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.hiset
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'hiset', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'hiset', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateKamakani: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.kamakani
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'kamakani', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'kamakani', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateTABE: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.tabe
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'tabe', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'tabe', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateTracing: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.tracing
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'tracing', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'tracing', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateUtilization: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().reports.reports.utilization
        let params = new URLSearchParams()
        const token = localStorage.getItem('jwt')
        if (state === undefined) {
            let data = qs.parse(window.location.search.substr(1, window.location.search.length))
            buildParams(data, params)
        } else {
            buildParams(state.data, params)
        }
        dispatch({ type: 'LOADING_REPORT', report: 'utilization', loading: true } as LoadingReportAction)
        dispatch(send({ report: 'utilization', data: groupParamsByKey(params), authorization: token }, 'REPORT') as any)
    },
    generateDOE: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        let data = qs.parse(window.location.search.substr(1, window.location.search.length));
        buildParams(data, params)
        dispatch({ type: 'LOADING_REPORT', report: 'doe', loading: true } as LoadingReportAction)
        const reportData = await agent.Reports.fetchDOE(params)
        dispatch({ type: 'GENERATE_REPORT', report: 'doe', data: reportData } as GenerateReportAction)
    },
    generateNotificationsWarning: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        let data = qs.parse(window.location.search.substr(1, window.location.search.length));
        buildParams(data, params)
        dispatch({ type: 'LOADING_REPORT', report: 'notificationsWarning', loading: true } as LoadingReportAction)
        const reportData = await agent.Reports.fetchNotificationsWarning(params)
        dispatch({ type: 'GENERATE_REPORT', report: 'notificationsWarning', data: reportData } as GenerateReportAction)
    },
    generateClassEnrollment: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        let data = qs.parse(window.location.search.substr(1, window.location.search.length));
        buildParams(data, params)
        dispatch({ type: 'LOADING_REPORT', report: 'classEnrollment', loading: true } as LoadingReportAction)
        const reportData = await agent.Reports.fetchClassEnrollment(params)
        dispatch({ type: 'GENERATE_REPORT', report: 'classEnrollment', data: reportData } as GenerateReportAction)
    },
    updateDescription: (state: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var report = getState().reports.report
        dispatch({ type: 'UPDATE_REPORT_DESCRIPTION', report: report, state: state } as UpdateDescriptionAction)
    },
    saveDescription: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var name = getState().reports.report
        var report = getState().reports.reports[name]
        let data = { report: name, type: report.data.reportType, content: JSON.stringify(convertToRaw(report.description.getCurrentContent())) }
        const { error } = await agent.Admin.saveReportDescription(data)
        if (error !== null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Report description saved')
        }
    },
    searchClasses: (report: string, value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().panes
        var data = getState().reports.reports[report].data
        dispatch({ type: 'SEARCH_REPORT_CLASSES_LOADING', report: report, value: value } as ClassesLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            buildParams(data, params)
            const { classes } = await agent.Data.searchClasses(params)
            dispatch({ type: 'SEARCH_REPORT_CLASSES', report: report, classes: classes } as SearchClassesAction)
        }, 1500)
    },
    searchRosterClasses: (report: string, value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().panes
        var data = getState().reports.reports[report].data
        dispatch({ type: 'SEARCH_REPORT_CLASSES_ROSTER_LOADING', report: report, value: value } as ClassesRosterLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            buildParams(data, params)
            const { classes } = await agent.Data.searchTemplates(params)
            dispatch({ type: 'SEARCH_REPORT_ROSTER_CLASSES', report: report, classes: classes } as SearchRosterClassesAction)
        }, 1500)
    },
    selectClass: (report: any, data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SELECT_REPORT_CLASS', report: report, key: data.key, value: data.title } as SelectClassAction)
    },
    selectRosterClass: (report: any, data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SELECT_REPORT_ROSTER_CLASS', report: report, key: data.key, value: data.title } as SelectRosterClassAction)
    },
    searchInmates: (value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().panes
        var data = getState().reports.reports.attendance.data
        dispatch({ type: 'SEARCH_REPORT_INMATES_LOADING', value: value } as InmatesLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            buildParams(data, params)
            const { inmates } = await agent.Data.searchInmates(params)
            dispatch({ type: 'SEARCH_REPORT_INMATES', inmates: inmates } as SearchInmatesAction)
        }, 1500)
    },
    selectInmate: (data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SELECT_REPORT_INMATE', key: data.key, value: data.title } as SelectInmateAction)
    },
    searchInstructors: (value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().panes
        var data = getState().reports.reports.attendance.data
        dispatch({ type: 'SEARCH_REPORT_INSTRUCTORS_LOADING', value: value } as InstructorsLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            buildParams(data, params)
            const { instructors } = await agent.Data.searchInstructors(params)
            dispatch({ type: 'SEARCH_REPORT_INSTRUCTORS', instructors: instructors } as SearchInstructorsAction)
        }, 1500)
    },
    selectInstructor: (data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SELECT_REPORT_INSTRUCTOR', key: data.key, value: data.title } as SelectInstructorAction)
    }
}

export const reducer: Reducer<ReportsState> = (state: ReportsState | undefined, incomingAction: Action): ReportsState => {
    if (state === undefined) {
        return {
            report: '',
            reports: {},
            favorites: [],
            connected: false,
            options: {
                ethnicities: [],
                inmates: [],
                facilities: [],
                programs: [],
                positions: []
            }
        }
    }

    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REPORT::OPEN':
            return {
                ...state,
                connected: true
            }
        case 'REPORT::CLOSED':
            return {
                ...state,
                connected: false
            }
        case 'REPORT::MESSAGE':
            const payload = action.payload.message
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [payload.report]: {
                        ...state.reports[payload.report],
                        loading: false,
                        ready: true,
                        generated: payload.data,
                        copyright: payload.copyright
                    }
                }
            }
        case 'SET_REPORT_URL':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        url: action.url
                    }
                }
            }
        case 'SET_REPORT_URLS':
            let urls = state.reports[action.report].urls
            urls.push({ title: action.title, url: action.url })
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        urls: urls
                    }
                }
            }
        case 'SET_EDHISTORY_URL':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    edhistory: {
                        ...state.reports.edhistory,
                        generated: {
                            ...state.reports.edhistory.generated,
                            inmates: _.map(state.reports.edhistory.generated.inmates, (data:any) => data.inmate.recordID === action.recordID ? Object.assign(_.clone(data), { url: action.url }) : data)
                        }
                    }
                }
            }
        case 'CHANGE_REPORT':
            return {
                ...state,
                report: action.report,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        data: action.data || reportDefaults[action.report],
                        description: createEditorState(),
                        url: '',
                        copyright: '',
                        urls: [],
                        loading: false,
                        ready: false,
                        generated: {},
                        facilities: action.facilities,
                        programs: action.programs,
                        funding: action.funding,
                        positions: action.positions,
                        levels: action.levels
                    }
                }
            }
        case 'FETCH_REPORT_FAVORITES':
            return {
                ...state,
                favorites: action.favorites
            }
        case 'FETCH_DESCRIPTION':
            return {
                ...state,
                report: action.report,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        description: createEditorState(JSON.parse(action.description))
                    }
                }
            }
        case 'REPORT_DATA_CHANGE':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        ready: false,
                        generated: {},
                        data: {
                            ...state.reports[action.report].data,
                            [action.key]: action.value
                        }
                    }
                }
            }
        case 'UPDATE_REPORT_DESCRIPTION':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        description: action.state
                    }
                }
            }
        case 'CLEAR_EDHISTORY_INMATES':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    edhistory: {
                        ...state.reports.edhistory,
                        data: {
                            ...state.reports.edhistory.data,
                            inmate: ''
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_INMATES_LOADING':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            inmate: action.value,
                            inmateID: 0,
                            inmatesLoading: true
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_INSTRUCTORS_LOADING':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            instructor: action.value,
                            instructorID: 0,
                            instructorsLoading: true
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_CLASSES_LOADING':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            class: action.value,
                            classID: 0,
                            classesLoading: true
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_CLASSES_ROSTER_LOADING':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            class: action.value,
                            classID: 0,
                            classesLoading: true
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_INMATES':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            inmates: action.inmates,
                            inmatesLoading: false
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_INSTRUCTORS':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            instructors: action.instructors,
                            instructorsLoading: false
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_CLASSES':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            classes: action.classes,
                            classesLoading: false
                        }
                    }
                }
            }
        case 'SEARCH_REPORT_ROSTER_CLASSES':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            classes: action.classes,
                            classesLoading: false
                        }
                    }
                }
            }
        case 'SELECT_REPORT_INMATE':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            inmate: action.value,
                            inmateID: action.key,
                            inmatesLoading: false
                        }
                    }
                }
            }
        case 'SELECT_REPORT_INSTRUCTOR':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    attendance: {
                        ...state.reports.attendance,
                        data: {
                            ...state.reports.attendance.data,
                            instructor: action.value,
                            instructorID: action.key,
                            instructorsLoading: false
                        }
                    }
                }
            }
        case 'SELECT_REPORT_CLASS':
            var filterClasses = state.reports[action.report].data.filterClasses
            filterClasses.push({ key: action.key, value: action.value })
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            class: '',
                            classID: action.key,
                            filterClasses: filterClasses,
                            classesLoading: false
                        }
                    }
                }
            }
        case 'SELECT_REPORT_ROSTER_CLASS':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        data: {
                            ...state.reports[action.report].data,
                            class: action.value,
                            classID: action.key,
                            classesLoading: false
                        }
                    }
                }
            }
        case 'SEARCH_EDHISTORY_INMATES':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    edhistory: {
                        ...state.reports.edhistory,
                        data: {
                            ...state.reports.edhistory.data,
                            inmate: action.value,
                            inmatesLoading: true
                        }
                    }
                }
            }
        case 'UPDATE_EDHISTORY_INMATES':
            var inmates = action.inmates
            _.each(state.reports.edhistory.data.inmates, (recordID:number) => {
                var inmate = _.find(state.options.inmates, (i:any) => i.key === recordID)
                if (!_.any(action.inmates, (i:any) => i.key === recordID)) {
                    inmates.unshift(inmate)
                }
            })
            return {
                ...state,
                reports: {
                    ...state.reports,
                    edhistory: {
                        ...state.reports.edhistory,
                        data: {
                            ...state.reports.edhistory.data,
                            inmatesLoading: false
                        }
                    }
                },
                options: {
                    ...state.options,
                    inmates: action.inmates
                }
            }
        case 'LOADING_REPORT':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        loading: action.loading,
                        ready: false,
                        url: '',
                        urls: [],
                        generated: {}
                    }
                }
            }
        case 'FETCH_ETHNICITIES':
            return {
                ...state,
                options: {
                    ...state.options,
                    ethnicities: action.ethnicities
                }
            }
        case 'FETCH_FACILITIES':
            return {
                ...state,
                options: {
                    ...state.options,
                    facilities: action.facilities
                }
            }
        case 'FETCH_POSITIONS':
            return {
                ...state,
                options: {
                    ...state.options,
                    positions: action.positions
                }
            }
        case 'FETCH_PROGRAMS':
            return {
                ...state,
                options: {
                    ...state.options,
                    programs: action.programs
                }
            }
        case 'GENERATE_REPORT':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.report]: {
                        ...state.reports[action.report],
                        loading: false,
                        ready: true,
                        generated: action.data
                    }
                }
            }
        default:
            return state
    }
}
